<template>
  <div class="queryPart">
    <div style="background: linear-gradient(to right, #1677ff, #2f99ff)">
      <nav-bar
        title="查零件号"
        leftColor="#fff"
        style="
          background: linear-gradient(to right, #1677ff, #2f99ff);
          color: #fff;
        "
      ></nav-bar>
      <div class="search">
        <input
          type="text"
          placeholder="请输入OE号"
          class="inp"
          v-model="part"
        />
        <van-icon name="search" size="22" @click="searchPart" />
      </div>
      <div class="history flex" v-if="!isResult">
        <div class="title">历史查询</div>
        <div class="clear" @click="clearHistory" v-show="history.length > 0">
          清空
        </div>
      </div>
    </div>

    <div
      class="history"
      v-if="isResult && list.length > 0"
      style="
        text-align: center;
        line-height: 1;
        display: flex;
        align-items: center;
      "
    >
      <div style="color: #767676">
        已为您找到
        <span style="color: #161616">{{ list.length }}</span> 件匹配的零件
      </div>
    </div>
    <div v-if="!isResult">
      <div v-if="history.length > 0">
        <div class="list" v-for="(item, index) in history" :key="index">
          <div v-if="item.oe" @click="searchPart(item.oe)">
            {{ item.oe}}
          </div>
        </div>
      </div>
      <div v-else style="margin: 80px; text-align: center">暂无历史记录</div>
    </div>
    <div v-else class="carConfig">


      <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
          v-show="list.length > 0"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="toPartDetail(item)"
        >
          <div class="part van-hairline--bottom">{{ item.goodsName }}</div>
          <div class="flex">
            <div style="color: #767676; width: 90px">OE号：</div>
            <div style="color: #464646; flex: 1; text-align: right">
              {{ item.oe }}
            </div>
          </div>
          <div class="flex">
            <div style="color: #767676; width: 90px">品牌：</div>
            <div style="color: #464646; flex: 1; text-align: right">
              {{ item.brandName }}
            </div>
          </div>
          <div class="flex">
            <div style="color: #767676">4S店参考价：</div>
            <div style="color: #ff7444">
              <span v-if="item.price"
                >¥{{ Number(item.price).toFixed(2) }}</span
              >
            </div>
          </div>
        </div>
          </van-list>
        </van-pull-refresh>
        <div v-show="list.length <= 0" style="margin: 80px; text-align: center">
        该OE号未匹配到零件数据
      </div>


      
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import {
  historySelectForOes,
  getInfoByOes,
  deleteHistorySelectForOes,
} from "@/api";
import { Search, Icon, Field, Dialog,List,
  PullRefresh, } from "vant";
export default {
  name: "queryPart",
  components: {
    NavBar,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      part: "",//oe
      historyOe: '',//历史OE
      isResult: false, //识别成功标识
      list: [], //查询到的OE
      history: [], //历史记录,
      userId: "",
      loading: false,
      finished: false,
      refreshing: false,
      current: 1,
      size: 20,
    };
  },
  methods: {
    //搜索按钮点击
    searchPart(oe) {
      if (oe && !oe.target) {
        this.historyOe = oe;
        this.part = oe;
      }else{
        this.historyOe = '';
      }
      this.onLoad()
      // getInfoByOes({ userId: this.userId, oes: isHistory && historyOe || this.part }).then((res) => {
      //   console.log(res);
      //   if (res.data.data && 0 in res.data.data.list) {
      //     this.list = res.data.data.list;
      //     this.isResult = true;
      //   }
      // });
    },
    //供应商
    onLoad() {
      var { userId, current, size, part, historyOe } = this;
      var obj = {
        userId,
        oes: historyOe || part,
        pageNo: 1,
        pageSize: size * current,
      }; //最后取这个
      this.finished = false;
      this.loading = true;
      getInfoByOes(obj)
        .then((res) => {
          console.log(res);
          if (res && res.data.status.code == 0) {
            var totalCount = (res.data.data && res.data.data.totalCount) || 0;
            var list = (res.data.data && res.data.data.list) || [];
            if (list.length == 0) {
              this.finished = true;
            }
            this.isResult = true;
            this.list = [...list];
            this.loading = false;
            this.current = this.current + 1;
            this.refreshing = false;
            this.finished = false;
            console.log(totalCount, obj.pageSize);
            if (totalCount <= obj.pageSize) {
              this.finished = true;
            }
          } else {
            this.finished = true;
          }
        })
        .catch((err) => {
          this.finished = true;
          this.refreshing = false;
          this.loading = false;
        });
    },
    //下拉刷新
    onRefresh() {
      this.current = 1;
      this.list = [];
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    //清空当前选择
    clearNow() {
      this.part = "";
    },
    //查看零件详情
    toPartDetail(item) {
      this.$router.push({
        name: "partDetail",
        params: { goodsId: item.goodsId },
      });
    },
    //清空历史记录
    clearHistory() {
      var that = this;
      Dialog.confirm({
        title: "提示",
        message: "确定要删除历史记录吗？",
      }).then(() => {
        deleteHistorySelectForOes({ userId: this.userId }).then((res) => {
          that.history = [];
        });
      });
    },
    //查询历史记录
    historySelectForOes() {
      historySelectForOes({ userId: this.userId }).then((res) => {
        if (res.data.data && res.data.data.list) {
          this.history = res.data.data.list || [];
        }
      });
    },
  },
  mounted() {
    this.userId = sessionStorage.getItem("userId");
    this.historySelectForOes();
  },
};
</script>

<style lang="less" scoepd>
.queryPart {
  .van-search {
    background: linear-gradient(to right, #1677ff, #2f99ff);
  }
  .van-nav-bar__left i {
    color: #fff !important;
  }
  .van-ellipsis span {
    font-size: 18px;
    font-weight: 500;
    color: #fff !important;
  }
  .carConfig {
    padding: 0 15px 20px;
    background: #f8f8f8;
    .item {
      background: #fff;
      border-radius: 6px;
      padding: 0 15px 10px;
      margin-bottom: 20px;
      .part {
        color: #161616;
        height: 47px;
        line-height: 47px;
        font-weight: bold;
        font-size: 16px;
      }
      .flex {
        margin: 10px 0;
        word-break: break-all;
      }
    }
  }
  .list {
    padding: 10px 15px;
    word-break: break-all;
    border-bottom: 1px solid #ccc;
  }
  .history {
    margin-top: 10px;
    padding: 0 15px;
    height: 50px;
    background: #f8f8f8;
    .title {
      font-weight: bold;
      font-size: 16px;
    }
    .clear {
      color: #fe9a23;
    }
  }
  .search {
    padding: 60px 15px 10px;
    position: relative;
    width: 100%;
    .inp {
      height: 35px;
      border: 1px solid #ccc;
      line-height: 35px;
      border-radius: 10px;
      width: 100%;
      text-indent: 10px;
    }
    .van-icon {
      position: absolute;
      right: 25px;
      bottom: 15px;
    }
  }
}
</style>